import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { ProductActionTypes } from '../../products-store/actions';
import { QuestionsActionTypes } from '../../questions-store/actions';
import { catchError, map, mergeMap, tap, switchMap } from 'rxjs/operators';
import { CruxHttpClientService } from '@crux/services';
import { environment } from './../../../../environments/environment';
import { Product } from './../../../model/product';
import { format, addYears } from 'date-fns';
import {
    transformQuestions,
    SCIUWtransformQuestions,
    getProductTypeCode,
    SCICyberUWtransformQuestions,
} from './transformation';
import { Question, Questions } from './../../../model/questions';
import { coerceNumberProperty } from '@angular/cdk/coercion';
import { Router } from '@angular/router';
import { CommonCommunicationService } from '../../../services/common-communication.service';
import { AffinityPartnerFlags } from '../../../model/affinity-partner';
import { find, isEmpty, isNil } from 'lodash';
import { AnalyticsService } from '@crux/services';
import { QuoteStep } from '../../../model/quote-step';

const affinityPartnerFlags = new AffinityPartnerFlags();
let producerCodesContent: any;
@Injectable()
export class ProductEffects {
    products: Product[];
    questions: Question[];
    eligibilityQuestions: any;
    questionsApi: string = environment.backend + environment.uri.questions;
    getSciQuestionsApi: string =
        environment.backend + environment.uri.scigetquestions;
    getSciCyberQuestionsApi: string =
        environment.backend + environment.uri.scicybergetuwq;
    updateSciQuestionsApi: string =
        environment.backend + environment.uri.sciupdatequestions;
    sciQuestionsFromAPI: any;
    sciCyberQuestionsFromAPI: any;
    revenue: any;
    totalSelectedPackage: number;
    newPremium: number;
    newSCIPremium: number;
    totalPremium: number;
    questionsFromAPIResponse: any;
    selectedProduct: string;
    partner: string;
    previousInsurance: boolean;
    public quoteStep: QuoteStep = new QuoteStep();

    constructor(
        private _store: Store<{
            products: Product[];
            questions: Question[];
            setEligibility: Questions[];
            AffinityPartnerFlags: AffinityPartnerFlags;
            producerCodesInfo: any;
            setQuestionsAnswer: Questions[];
            quoteStep: QuoteStep;
        }>,
        private _action: Actions,
        private _http: CruxHttpClientService,
        public _router: Router,
        private _ccs: CommonCommunicationService,
        private _analytics: AnalyticsService
    ) {
        this._store.pipe(select('products')).subscribe((productsFromStore) => {
            this.products = productsFromStore;
        });
        this._store.pipe(select('questions')).subscribe((questions) => {
            this.questions = questions;
        });
        this._store.pipe(select('quoteStep')).subscribe((quoteStep) => {
            this.quoteStep = quoteStep;
            if (quoteStep.insurance) {
                if (quoteStep.insurance.value === 'Yes') {
                    this.previousInsurance = true;
                } else {
                    this.previousInsurance = false;
                }
            }
        });
        this._store
            .pipe(select('setEligibility'))
            .subscribe((eligibilityFromStore) => {
                this.eligibilityQuestions = eligibilityFromStore;
            });
        this._store
            .pipe(select('AffinityPartnerFlags'))
            .subscribe((AffinityPartnerFlags) => {
                if (
                    AffinityPartnerFlags &&
                    AffinityPartnerFlags.partnercode &&
                    !isNil(AffinityPartnerFlags.partnercode) &&
                    !isEmpty(AffinityPartnerFlags.partnercode)
                ) {
                    affinityPartnerFlags.partnercode =
                        AffinityPartnerFlags.partnercode;
                    this.partner = affinityPartnerFlags.partnercode;
                }
                if (
                    affinityPartnerFlags.partnercode &&
                    !isNil(affinityPartnerFlags.partnercode) &&
                    !isEmpty(affinityPartnerFlags.partnercode) &&
                    affinityPartnerFlags.partnercode === 'amazon10'
                ) {
                    this.getSciQuestionsApi =
                        environment.backend + environment.uri.sciGetAmazonUWQ;
                } else {
                    this.getSciQuestionsApi = this.getSciQuestionsApi;
                }
            });
        this._store
            .pipe(select('producerCodesInfo'))
            .subscribe((producerCodesInfo) => {
                if (!isNil(producerCodesInfo) && !isEmpty(producerCodesInfo)) {
                    producerCodesContent = producerCodesInfo;
                }
            });
        if (
            this._ccs.PartnerId ===
                CommonCommunicationService.PARTNER_BOLDPENGUIN ||
            this._ccs.PartnerId === CommonCommunicationService.PARTNER_BRIZA
        ) {
            this.getSciQuestionsApi =
                environment.backend + environment.uri.scigetquestions;
        } else {
            this.getSciQuestionsApi =
                environment.backend + environment.uri.scigetsemciuwq;
        }
    }

    @Effect()
    getQuestions$: Observable<any> = this._action.pipe(
        ofType(ProductActionTypes.getQuestions),
        mergeMap((action) =>
            this._http
                .get(
                    this.questionsApi
                        .replace('#PARTNER#', this._ccs.PartnerId)
                        .replace(
                            '$classid$',
                            action['payload']['classData']['digitalClassCode']
                        )
                        .replace(
                            '$productid$',
                            action['payload']['allSelectedProductsProductIds']
                        )
                )
                .pipe(
                    tap((questionsFromAPI) => {
                        if (
                            questionsFromAPI &&
                            questionsFromAPI.status &&
                            questionsFromAPI.status === 'Success'
                        ) {
                            //GA Event to track questions returned
                            this._analytics.eventTrack.next({
                                category: 'API Performance',
                                action: 'QuestionsV2 API - SUCCESS',
                                label:
                                    'TTS: ' +
                                    action['payload']['classData'][
                                        'digitalClassCode'
                                    ] +
                                    '; Product: ' +
                                    action['payload'][
                                        'allSelectedProductsProductIds'
                                    ] +
                                    '; Request ID: ' +
                                    (questionsFromAPI.requestId
                                        ? questionsFromAPI.requestId
                                        : ''),
                            });
                        } else {
                            this._analytics.eventTrack.next({
                                category: 'API Performance',
                                action: 'QuestionsV2 API - FAILURE',
                                label:
                                    'TTS: ' +
                                    action['payload']['classData'][
                                        'digitalClassCode'
                                    ] +
                                    '; Product: ' +
                                    action['payload'][
                                        'allSelectedProductsProductIds'
                                    ] +
                                    ', Status: FAILURE-500' +
                                    '; Request ID: ' +
                                    (questionsFromAPI.requestId
                                        ? questionsFromAPI.requestId
                                        : ''),
                            });
                        }
                    }),
                    map((questionsFromAPI) => ({
                        type: QuestionsActionTypes.setQuestions,
                        payload: transformQuestions(
                            questionsFromAPI,
                            // tslint:disable-next-line:max-line-length
                            action['payload']['selectedProducts'][0][
                                'productSourceSystem'
                            ]
                                ? action['payload']['selectedProducts'][0][
                                      'productSourceSystem'
                                  ]
                                : 'MICRO',
                            action,
                            this.questions,
                            null,
                            this._router.url,
                            this._ccs.stateSelected,
                            this.partner,
                            this.previousInsurance
                        ),
                    })),
                    catchError((error) => {
                        this._analytics.eventTrack.next({
                            category: 'API Performance',
                            action: 'QuestionsV2 API - FAILURE',
                            label:
                                'TTS: ' +
                                action['payload']['classData'][
                                    'digitalClassCode'
                                ] +
                                '; Product: ' +
                                action['payload'][
                                    'allSelectedProductsProductIds'
                                ] +
                                '; Status: Time Out',
                        });
                        return of({
                            type: QuestionsActionTypes.setQuestionsError,
                            payload: error,
                        });
                    })
                )
        )
    );

    @Effect()
    getSciQuestions$: Observable<any> = this._action.pipe(
        ofType(ProductActionTypes.getSciQuestions),
        mergeMap((action) =>
            this._http
                .post(this.getSciQuestionsApi, {
                    body: action['payload'],
                })
                .pipe(
                    tap((sciQuestionsFromAPI) => {
                        this.sciQuestionsFromAPI = sciQuestionsFromAPI;
                        this._ccs.attemptsToTriggerSciUWQ =
                            this._ccs.attemptsToTriggerSciUWQ + 1;
                        /**
                         * ! Persist policySubTypeCode and policyTypeCode in products
                         */
                        if (
                            this.sciQuestionsFromAPI
                                .underwritingQuestionsResponse &&
                            this.sciQuestionsFromAPI
                                .underwritingQuestionsResponse
                                .underwritingQuestionsData &&
                            this.sciQuestionsFromAPI
                                .underwritingQuestionsResponse
                                .underwritingQuestionsData
                                .underwritingQuestionsLookup
                        ) {
                            this._analytics.eventTrack.next({
                                category: 'API Performance',
                                action: 'SEMCI UWQ API - SUCCESS',
                                label:
                                    'Business Name: ' +
                                    this._ccs.businessInsuredName +
                                    '; TTS: ' +
                                    action['payload'][
                                        'underwritingQuestionsRequest'
                                    ]['FilterInfo']['TTS'] +
                                    '; STATE: ' +
                                    action['payload'][
                                        'underwritingQuestionsRequest'
                                    ]['FilterInfo']['State'] +
                                    '; Request ID: ' +
                                    (this.sciQuestionsFromAPI.requestId
                                        ? this.sciQuestionsFromAPI.requestId
                                        : ''),
                            });
                            let questionsWithEmptyText = [];
                            this.sciQuestionsFromAPI.underwritingQuestionsResponse.underwritingQuestionsData.underwritingQuestionsLookup.forEach(
                                (question) => {
                                    if (
                                        isEmpty(question.questionDescription) ||
                                        isNil(question.questionDescription)
                                    ) {
                                        questionsWithEmptyText.push(question);
                                    }
                                }
                            );
                            if (
                                questionsWithEmptyText.length ===
                                this.sciQuestionsFromAPI
                                    .underwritingQuestionsResponse
                                    .underwritingQuestionsData
                                    .underwritingQuestionsLookup.length
                            ) {
                                if (this._ccs.attemptsToTriggerSciUWQ === 2) {
                                    this._ccs.gotSciQuestions = true;
                                    this._ccs.sciGetquestionsTriggered = true;
                                }
                            } else {
                                this._ccs.gotSciQuestions = true;
                                this._ccs.sciGetquestionsTriggered = true;
                            }
                        } else if (
                            this.sciQuestionsFromAPI.data &&
                            this.sciQuestionsFromAPI.data.questions
                        ) {
                            this._analytics.eventTrack.next({
                                category: 'API Performance',
                                action: 'Pattern C UWQ API - SUCCESS',
                                label:
                                    'Business Name: ' +
                                    this._ccs.businessInsuredName +
                                    '; TTS: ' +
                                    action['payload']['classCode'] +
                                    '; STATE: ' +
                                    action['payload']['stateCode'] +
                                    '; Request ID: ' +
                                    (this.sciQuestionsFromAPI.requestId
                                        ? this.sciQuestionsFromAPI.requestId
                                        : ''),
                            });
                            let questionsWithEmptyText = [];
                            this.sciQuestionsFromAPI.data.questions.forEach(
                                (question) => {
                                    if (
                                        isEmpty(question.questionText) ||
                                        isNil(question.questionText)
                                    ) {
                                        questionsWithEmptyText.push(question);
                                    }
                                }
                            );
                            if (
                                questionsWithEmptyText.length ===
                                this.sciQuestionsFromAPI.data.questions.length
                            ) {
                                if (this._ccs.attemptsToTriggerSciUWQ === 2) {
                                    this._ccs.gotSciQuestions = true;
                                    this._ccs.sciGetquestionsTriggered = true;
                                }
                            } else {
                                this._ccs.gotSciQuestions = true;
                                this._ccs.sciGetquestionsTriggered = true;
                            }
                        } else {
                            if (
                                this.partner &&
                                !isNil(this.partner) &&
                                !isEmpty(this.partner) &&
                                this.partner === 'amazon10'
                            ) {
                                this._analytics.eventTrack.next({
                                    category: 'API Performance',
                                    action: 'Pattern C API - FAILURE',
                                    label:
                                        'Business Name: ' +
                                        this._ccs.businessInsuredName +
                                        '; TTS: ' +
                                        action['payload']['classCode'] +
                                        '; STATE: ' +
                                        action['payload']['stateCode'] +
                                        '; Status: 500-Internal Server Error; Request ID: ' +
                                        (this.sciQuestionsFromAPI.requestId
                                            ? this.sciQuestionsFromAPI.requestId
                                            : ''),
                                });
                            } else {
                                this._analytics.eventTrack.next({
                                    category: 'API Performance',
                                    action: 'SEMCI UWQ API - FAILURE',
                                    label:
                                        'Business Name: ' +
                                        this._ccs.businessInsuredName +
                                        '; TTS: ' +
                                        action['payload'][
                                            'underwritingQuestionsRequest'
                                        ]['FilterInfo']['TTS'] +
                                        '; STATE: ' +
                                        action['payload'][
                                            'underwritingQuestionsRequest'
                                        ]['FilterInfo']['State'] +
                                        '; Status: 500-Internal Server Error; Request ID: ' +
                                        (this.sciQuestionsFromAPI.requestId
                                            ? this.sciQuestionsFromAPI.requestId
                                            : ''),
                                });
                            }
                        }
                        this.products.forEach((product: Product) => {
                            if (
                                product.productSourceSystem === 'SCI' &&
                                product.productId !== ''
                            ) {
                                const productData = getProductTypeCode(
                                    product.productId
                                );
                                product.policySubTypeCode =
                                    productData.policySubTypeCode;
                                product.policyTypeCode =
                                    productData.policyTypeCode;
                            }
                            if (
                                product.selected &&
                                (product.productId === 'BOP' ||
                                    product.productId === 'GL')
                            ) {
                                this.selectedProduct = product.productId;
                            }
                        });
                    }),
                    map(() => ({
                        type: ProductActionTypes.setProducts,
                        payload: this.products,
                    })),
                    map(() => ({
                        type: QuestionsActionTypes.setSciQuestions,
                        payload: SCIUWtransformQuestions(
                            this.sciQuestionsFromAPI,
                            this.selectedProduct
                        ),
                    })),
                    catchError((error) => {
                        if (
                            this.partner &&
                            !isNil(this.partner) &&
                            !isEmpty(this.partner) &&
                            this.partner === 'amazon10'
                        ) {
                            this._analytics.eventTrack.next({
                                category: 'API Performance',
                                action: 'Pattern C API - FAILURE',
                                label:
                                    'Business Name: ' +
                                    this._ccs.businessInsuredName +
                                    '; TTS: ' +
                                    action['payload']['classCode'] +
                                    '; STATE: ' +
                                    action['payload']['stateCode'] +
                                    '; Status: Time Out',
                            });
                        } else {
                            this._analytics.eventTrack.next({
                                category: 'API Performance',
                                action: 'SEMCI UWQ API - FAILURE',
                                label:
                                    'Business Name: ' +
                                    this._ccs.businessInsuredName +
                                    '; TTS: ' +
                                    action['payload'][
                                        'underwritingQuestionsRequest'
                                    ]['FilterInfo']['TTS'] +
                                    '; STATE: ' +
                                    action['payload'][
                                        'underwritingQuestionsRequest'
                                    ]['FilterInfo']['State'] +
                                    '; Status: Time Out',
                            });
                        }
                        return of({
                            type: QuestionsActionTypes.setSciQuestionsError,
                            payload: error,
                        });
                    })
                )
        )
    );

    @Effect()
    getSciCyberQuestions$: Observable<any> = this._action.pipe(
        ofType(ProductActionTypes.getSciCyberQuestions),
        mergeMap((action) =>
            this._http
                .post(this.getSciCyberQuestionsApi, {
                    body: action['payload'],
                })
                .pipe(
                    tap((sciCyberQuestionsFromAPI) => {
                        this.sciCyberQuestionsFromAPI = sciCyberQuestionsFromAPI;
                        if (
                            this.sciCyberQuestionsFromAPI &&
                            this.sciCyberQuestionsFromAPI.length > 0
                        ) {
                            this._analytics.eventTrack.next({
                                category: 'API Performance',
                                action: 'CYBER UWQ API - SUCCESS',
                                label:
                                    'Business Name: ' +
                                    this._ccs.businessInsuredName +
                                    '; TTS: ' +
                                    action['payload']['digitalClassCode'] +
                                    '; STATE: ' +
                                    action['payload']['state'],
                            });
                        } else {
                            this._analytics.eventTrack.next({
                                category: 'API Performance',
                                action: 'CYBER UWQ API - FAILURE',
                                label:
                                    'Business Name: ' +
                                    this._ccs.businessInsuredName +
                                    '; TTS: ' +
                                    action['payload']['digitalClassCode'] +
                                    '; STATE: ' +
                                    action['payload']['state'] +
                                    '; Status: 500-Internal Server Error',
                            });
                        }
                        /**
                         * ! Persist policySubTypeCode and policyTypeCode in products
                         */
                        if (this.sciCyberQuestionsFromAPI) {
                            this._ccs.gotSciCyberQuestions = true;
                        }

                        this.products.forEach((product: Product) => {
                            if (
                                product.productSourceSystem === 'SCI' &&
                                product.productId !== ''
                            ) {
                                const productData = getProductTypeCode(
                                    product.productId
                                );
                                product.policySubTypeCode =
                                    productData.policySubTypeCode;
                                product.policyTypeCode =
                                    productData.policyTypeCode;
                            }
                        });
                    }),
                    map(() => ({
                        type: ProductActionTypes.setProducts,
                        payload: this.products,
                    })),
                    map(() => ({
                        type: QuestionsActionTypes.setSciCyberQuestions,
                        payload: SCICyberUWtransformQuestions(
                            this.sciCyberQuestionsFromAPI,
                            this.partner
                        ),
                    })),
                    catchError((error) => {
                        this._analytics.eventTrack.next({
                            category: 'API Performance',
                            action: 'CYBER UWQ API - FAILURE',
                            label:
                                'Business Name: ' +
                                this._ccs.businessInsuredName +
                                '; TTS: ' +
                                action['payload']['digitalClassCode'] +
                                '; STATE: ' +
                                action['payload']['state'] +
                                '; Status: Time Out',
                        });
                        return of({
                            type:
                                QuestionsActionTypes.setSciCyberQuestionsError,
                            payload: error,
                        });
                    })
                )
        )
    );

    @Effect()
    getSciChildQuestions$: Observable<any> = this._action.pipe(
        ofType(ProductActionTypes.getSciChildQuestions),
        mergeMap((action) =>
            this._http
                .put(this.updateSciQuestionsApi, {
                    body: action['payload'],
                })
                .pipe(
                    map((sciQuestionsFromAPI) => ({
                        type: QuestionsActionTypes.setSciChildQuestions,
                        payload: transformQuestions(
                            sciQuestionsFromAPI,
                            'SCI',
                            null,
                            this.questions,
                            this.partner
                        ),
                    })),
                    catchError((error) => {
                        return of({
                            type:
                                QuestionsActionTypes.setSciChildQuestionsError,
                            payload: error,
                        });
                    })
                )
        )
    );

    @Effect()
    setPackageCount$: Observable<any> = this._action.pipe(
        ofType(ProductActionTypes.setProducts),
        tap(() => {
            this.totalSelectedPackage = 0;
            this.newPremium = 0;
            this.newSCIPremium = 0;
            this.totalPremium = 0;
        }),
        tap((action: any) =>
            action['payload'].forEach((product: Product) => {
                if (product.selected === true) {
                    if (
                        product.selectedpackage !== null &&
                        product.selectedpackage !== undefined &&
                        product.productId !== 'WORKCOMP' &&
                        (product.errorMessage !== 'Referral' ||
                            product.errorMessage !== 'Decline')
                    ) {
                        this.totalSelectedPackage += 1;
                        if (!product.add_property) {
                            this.newPremium += coerceNumberProperty(
                                product.selectedpackage.premium_amount.Premium
                            );
                        } else if (!product.add_property.selected) {
                            this.newPremium += coerceNumberProperty(
                                product.selectedpackage.premiumAmount
                            );
                        } else if (product.add_property.selected) {
                            this.newPremium += coerceNumberProperty(
                                product.upgradeProduct.selectedpackage
                                    .premiumAmount
                            );
                        }
                    } else if (product.premium_amount && product.addedToCart) {
                        this.totalSelectedPackage += 1;
                        this.newSCIPremium += coerceNumberProperty(
                            product.premium_amount.Premium
                        );
                    }
                }
                this.totalPremium = this.newPremium + this.newSCIPremium;
            })
        ),
        switchMap(() => [
            {
                type: ProductActionTypes.setPackageCount,
                payload: this.totalSelectedPackage,
            },
            {
                type: ProductActionTypes.setTotalPremium,
                payload: this.totalPremium,
            },
        ])
    );

    readProductsFromStore$(): Observable<Product[]> {
        return this._store.pipe(select('products'));
    }
}

export const getContractNumber = (partnerId, state) => {
    // Chubb PAS Producer Codes
    // Literally airlifted from below function.
    // Team Focus or PBN
    let producerCode: string;
    if (!isNil(producerCodesContent) && !isEmpty(producerCodesContent)) {
        producerCodesContent.forEach((partner) => {
            if (
                affinityPartnerFlags.partnercode &&
                !isNil(affinityPartnerFlags.partnercode) &&
                !isEmpty(affinityPartnerFlags.partnercode) &&
                partner.partnerId === affinityPartnerFlags.partnercode
            ) {
                if (partner.stateSpecificCodes.length === 0) {
                    producerCode = partner.defaultCode;
                } else {
                    const stateExist = find(partner.stateSpecificCodes, [
                        'state',
                        state,
                    ]);
                    if (!isNil(stateExist) && !isEmpty(stateExist)) {
                        producerCode = stateExist.code;
                    } else {
                        producerCode = partner.defaultCode;
                    }
                }
            } else if (partner.partnerId === partnerId) {
                if (partner.stateSpecificCodes.length === 0) {
                    producerCode = partner.defaultCode;
                } else {
                    const stateExist = find(partner.stateSpecificCodes, [
                        'state',
                        state,
                    ]);
                    if (!isNil(stateExist) && !isEmpty(stateExist)) {
                        producerCode = stateExist.code;
                    } else {
                        producerCode = partner.defaultCode;
                    }
                }
            }
        });
        return producerCode;
    } else {
        return 'Z07312';
    }
};

export const getUWCompanyName = (name: string) => {
    let UWCompanyName: string;
    switch (name) {
        case 'ACEPC':
            UWCompanyName = 'ACE Property & Casualty Insurance Company';
            break;

        case 'ACEFire':
            UWCompanyName = 'ACE Fire Underwriters Insurance Company';
            break;

        case 'PacificIndemnity':
            UWCompanyName = 'Pacific Indemnity Insurance Company';
            break;

        case 'PacificEmployers':
            UWCompanyName = 'Pacific Employers Insurance Company';
            break;

        default:
            UWCompanyName = name;
            break;
    }
    return UWCompanyName;
};

export const buildSciQuestionsRequestBody = (
    data,
    eligibilityQuestions,
    inventory?
) => {
    const sciQuestionRequestBody = {};
    // Temp variables (need to get them from questions)

    // let umbrellaSelected = true;
    let umbrellaSelected = false;
    if (data.allSelectedProductsProductIds.includes('UMBRELLA')) {
        umbrellaSelected = true;
    }

    let bopSelected = false;
    const productsArray = data.allSelectedProductsProductIds.split(',');
    if (productsArray.indexOf('BOP') !== -1) {
        bopSelected = true;
    }

    const product = data.product.productId;

    let effectiveDt = '';
    let expirationDt = '';

    let commercialName = '';
    let legalEntityCd = '';
    let operationsDesc = '';
    const id = 'LOC1';
    const addrTypeCd = 'Primary';
    let addr1 = '';
    const addr2 = '';
    let city = '';
    let state = '';
    let zip = '';

    let sqft = '';
    let payroll = '';

    let businessStartYear = '';
    let annualRevenue: string;
    // if (data.revenue) {
    annualRevenue = data.revenue.toString();
    // }
    let numEmployees = '';

    let unit = '';
    let eachMember = '';
    let avgNumOfAnimalsPerDay = '';
    let totalCost = '';
    let eachKennel = '';
    let eachShow = '';

    const setUniversalQuestionAnswers = () => {
        data.questions.forEach((questionGroup) => {
            questionGroup.questions.forEach((question) => {
                if (question.question_id === '1') {
                    commercialName = question.value;
                }
                if (question.question_id === '21') {
                    legalEntityCd = question.value.SCI;
                }
                if (question.question_id === '2') {
                    businessStartYear = question.value;
                }
                if (question.question_id === '3') {
                    annualRevenue = question.value;
                }
                if (question.question_id === '4') {
                    addr1 = question.value;
                }
                if (question.question_id === '5') {
                    city = question.value;
                }
                if (question.question_id === '6') {
                    state = question.value;
                }
                if (question.question_id === '7') {
                    zip = question.value;
                }
                if (question.question_id === '15') {
                    effectiveDt = convertDate(question.value, 'start');
                    expirationDt = convertDate(question.value, 'end');
                }
                if (question.question_id === '10') {
                    sqft = question.value;
                }
                if (question.question_id === '9') {
                    numEmployees = question.value;
                }
                if (question.question_id === '8') {
                    payroll = question.value;
                }
                if (question.question_id === '100') {
                    unit = question.value;
                }
                if (question.question_id === '101') {
                    eachMember = question.value;
                }
                if (question.question_id === '102') {
                    avgNumOfAnimalsPerDay = question.value;
                }
                if (question.question_id === '103') {
                    totalCost = question.value;
                }
                if (question.question_id === '104') {
                    eachKennel = question.value;
                }
                if (question.question_id === '105') {
                    eachShow = question.value;
                }
            });
        });
    };

    const convertDate = (date, type) => {
        let convertedDate;
        if (type === 'start') {
            convertedDate = format(Date.parse(date), 'yyyy-MM-dd');
        }
        if (type === 'end') {
            convertedDate = format(addYears(Date.parse(date), 1), 'yyyy-MM-dd');
        }
        return convertedDate;
    };

    const commlSublocation = {
        LocRef: 'LOC1',
        SubLocRef: 'BLD1',
        AreaOccupied: '100',
        BldgProtection: {
            NumStories: '2',
            BldgArea: '300',
            Sprinkler: '1',
            YearBuilt: '1980-08-13',
        },
    };
    let liabilityInfo = {
        CommlCoverage: [
            {
                CoverageCd: 'LBMED',
                LimitAppliesToCd: 'PerOcc',
                Limit: '1000000',
            },
            {
                CoverageCd: 'LBMED',
                LimitAppliesToCd: 'Aggregate',
                Limit: '2000000',
            },
            {
                CoverageCd: 'LBMED',
                LimitAppliesToCd: 'PerPerson',
                Limit: '5000',
            },
            {
                CoverageCd: 'LBMED',
                LimitAppliesToCd: 'ProductsCompletedAggregate',
                Limit: '2000000',
            },
        ],
    };

    const ebayLiabilityInfo = {
        CommlCoverage: [
            {
                CoverageCd: 'LBMED',
                LimitAppliesToCd: 'PerOcc',
                Limit: '300000',
            },
            {
                CoverageCd: 'LBMED',
                LimitAppliesToCd: 'Aggregate',
                Limit: '600000',
            },
            {
                CoverageCd: 'LBMED',
                LimitAppliesToCd: 'PerPerson',
                Limit: '5000',
            },
            {
                CoverageCd: 'LBMED',
                LimitAppliesToCd: 'ProductsCompletedAggregate',
                Limit: '600000',
            },
        ],
    };

    const umbrellaQuoteRateInputs = {
        CommlUmbrellaPolicyInqRq: {
            CommlUmbrellaLineBusiness: {
                CommlCoverage: {
                    Limit: {
                        UmbrellaLimit: '1000000',
                    },
                },
            },
        },
    };

    const propertyInfo = [
        {
            LocRef: 'LOC1',
            SubLocRef: 'BLD1',
            CommlCoverage: [
                {
                    CoverageCd: 'BPP',
                    Limit: '20000',
                },
            ],
        },
    ];

    const buildLiabilityInfo = () => {
        // Since we only allow one location, LOC1 is the only choice we have

        liabilityInfo['LocRef'] = 'LOC1';
        liabilityInfo['ClassCd'] = data.classData.digitalClassCode;
        liabilityInfo['PremiumBasisCd'] = data.classData.premiumBasis;
        switch (data.classData.premiumBasis) {
            case 'Area':
            case 'AREA':
                liabilityInfo['Exposure'] = sqft;
                break;
            case 'GRSALES':
            case 'SALES':
                liabilityInfo['Exposure'] = annualRevenue;
                break;
            case 'PAYRL':
            case 'PAY':
                liabilityInfo['Exposure'] = payroll;
                break;
            case 'Unit':
            case 'UNITS':
                liabilityInfo['Exposure'] = unit;
                break;
            case 'Member':
            case 'MEMBER':
                liabilityInfo['Exposure'] = eachMember;
                break;
            case 'Animal':
            case 'ANIMALS':
                liabilityInfo['Exposure'] = avgNumOfAnimalsPerDay;
                break;
            case 'TotCost':
            case 'COST':
                liabilityInfo['Exposure'] = totalCost;
                break;
            case 'Kennel':
            case 'KENNEL':
                liabilityInfo['Exposure'] = eachKennel;
                break;
            case 'Show':
            case 'SHOWS':
                liabilityInfo['Exposure'] = eachShow;
                break;
        }
    };

    setUniversalQuestionAnswers();
    buildLiabilityInfo();
    operationsDesc = data.classData.description;

    sciQuestionRequestBody['DigitalQuoteRateRequest'] = {};
    sciQuestionRequestBody['DigitalQuoteRateRequest'][
        'BOPQuoteRateInputs'
    ] = {};
    sciQuestionRequestBody['DigitalQuoteRateRequest']['BOPQuoteRateInputs'][
        'ProducerInfo'
    ] = {};
    const currentContractNumber = getContractNumber(data.partnerId, data.state);
    sciQuestionRequestBody['DigitalQuoteRateRequest']['BOPQuoteRateInputs'][
        'ProducerInfo'
    ]['ContractNumber'] = currentContractNumber;
    if (data.product) {
        data.product['contractNumber'] = currentContractNumber;
    }
    sciQuestionRequestBody['DigitalQuoteRateRequest']['BOPQuoteRateInputs'][
        'Product'
    ] = product;
    sciQuestionRequestBody['DigitalQuoteRateRequest']['BOPQuoteRateInputs'][
        'ContractTerm'
    ] = {};
    sciQuestionRequestBody['DigitalQuoteRateRequest']['BOPQuoteRateInputs'][
        'ContractTerm'
    ]['EffectiveDt'] = effectiveDt;
    sciQuestionRequestBody['DigitalQuoteRateRequest']['BOPQuoteRateInputs'][
        'ContractTerm'
    ]['ExpirationDt'] = expirationDt;
    sciQuestionRequestBody['DigitalQuoteRateRequest']['BOPQuoteRateInputs'][
        'InsuredOrPrincipal'
    ] = {};
    sciQuestionRequestBody['DigitalQuoteRateRequest']['BOPQuoteRateInputs'][
        'InsuredOrPrincipal'
    ]['CommercialName'] = commercialName;
    sciQuestionRequestBody['DigitalQuoteRateRequest']['BOPQuoteRateInputs'][
        'InsuredOrPrincipal'
    ]['LegalEntityCd'] = legalEntityCd;

    sciQuestionRequestBody['DigitalQuoteRateRequest']['BOPQuoteRateInputs'][
        'InsuredOrPrincipal'
    ]['OperationsDesc'] = operationsDesc;
    sciQuestionRequestBody['DigitalQuoteRateRequest']['BOPQuoteRateInputs'][
        'InsuredOrPrincipal'
    ]['Addr'] = [{}];

    // TODO: Handle address array push
    sciQuestionRequestBody['DigitalQuoteRateRequest']['BOPQuoteRateInputs'][
        'InsuredOrPrincipal'
    ]['Addr'][0]['Id'] = id;
    sciQuestionRequestBody['DigitalQuoteRateRequest']['BOPQuoteRateInputs'][
        'InsuredOrPrincipal'
    ]['Addr'][0]['AddrTypeCd'] = addrTypeCd;
    sciQuestionRequestBody['DigitalQuoteRateRequest']['BOPQuoteRateInputs'][
        'InsuredOrPrincipal'
    ]['Addr'][0]['Addr1'] = addr1;
    sciQuestionRequestBody['DigitalQuoteRateRequest']['BOPQuoteRateInputs'][
        'InsuredOrPrincipal'
    ]['Addr'][0]['Addr2'] = addr2;
    sciQuestionRequestBody['DigitalQuoteRateRequest']['BOPQuoteRateInputs'][
        'InsuredOrPrincipal'
    ]['Addr'][0]['City'] = city;
    sciQuestionRequestBody['DigitalQuoteRateRequest']['BOPQuoteRateInputs'][
        'InsuredOrPrincipal'
    ]['Addr'][0]['State'] = state;
    sciQuestionRequestBody['DigitalQuoteRateRequest']['BOPQuoteRateInputs'][
        'InsuredOrPrincipal'
    ]['Addr'][0]['Zip'] = zip;
    sciQuestionRequestBody['DigitalQuoteRateRequest']['BOPQuoteRateInputs'][
        'InsuredOrPrincipal'
    ]['BusinessInfo'] = {};
    sciQuestionRequestBody['DigitalQuoteRateRequest']['BOPQuoteRateInputs'][
        'InsuredOrPrincipal'
    ]['BusinessInfo']['BusinessStartYear'] = businessStartYear;
    sciQuestionRequestBody['DigitalQuoteRateRequest']['BOPQuoteRateInputs'][
        'InsuredOrPrincipal'
    ]['BusinessInfo']['AnnualRevenue'] = annualRevenue;
    sciQuestionRequestBody['DigitalQuoteRateRequest']['BOPQuoteRateInputs'][
        'InsuredOrPrincipal'
    ]['BusinessInfo']['NumEmployees'] = numEmployees;

    sciQuestionRequestBody['DigitalQuoteRateRequest']['BOPQuoteRateInputs'][
        'CommlSublocation'
    ] = [];
    sciQuestionRequestBody['DigitalQuoteRateRequest']['BOPQuoteRateInputs'][
        'CommlSublocation'
    ][0] = commlSublocation;
    sciQuestionRequestBody['DigitalQuoteRateRequest']['BOPQuoteRateInputs'][
        'LiabilityInfo'
    ] = [];

    // TODO: Get liability info from custom packages/business segment object

    sciQuestionRequestBody['DigitalQuoteRateRequest']['BOPQuoteRateInputs'][
        'LiabilityInfo'
    ][0] = liabilityInfo;

    // TODO: Handle PolicyLvlQuestions array push
    if (umbrellaSelected) {
        sciQuestionRequestBody['DigitalQuoteRateRequest'][
            'UmbrellaQuoteRateInputs'
        ] = umbrellaQuoteRateInputs;
    }
    if (bopSelected) {
        sciQuestionRequestBody['DigitalQuoteRateRequest']['BOPQuoteRateInputs'][
            'PropertyInfo'
        ] = inventory ? getEbayBpp(inventory) : propertyInfo;
    }
    return sciQuestionRequestBody;
};

export const getEbayBpp = (inventory) => {
    return [
        {
            LocRef: 'LOC1',
            SubLocRef: 'BLD1',
            CommlCoverage: [
                {
                    CoverageCd: 'BPP',
                    Limit: inventory.toString(),
                },
            ],
        },
    ];
};

// INFO (delete after SCI is complete)
/*
    PolicyLvlQuestions = elegibility questions
    ContractNumber not user input
    LegalEntityCd = company structure dropdown
    WebsiteURL optional
    OperationsDesc = class description (such as barber shop's description)
    CommlSublocation = building information (optional)
    LiabilityInfo.localref - loc1 (in case of one location)
    ClassCd = digitalclasscode
    PremiumBasisCd = is the value we are looking for from business details to populate the exposure
    Exposure = in businessSegment obj based on premiumbasiscd
    CommlCoverage = optional coverages LINK https://chubb.invisionapp.com/share/ZTO1MHBHFSJ#/screens/329292866
    PropertyInfo = same page as above ^ property dropdown value = limit (only for BOP)
    Umbrella selected from business details/additional info https://chubb.invisionapp.com/share/ZTO1MHBHFSJ#/screens/329292866
*/
