import { Component, OnInit } from '@angular/core';
import { DictionaryService } from '../../../services/dictionary.service';
import { Router } from '@angular/router';
import {
    findIndex,
    uniqBy,
    forEach,
    filter,
    map,
    sortBy,
    find,
    isNil,
    isEmpty,
} from 'lodash-es';
import { AnalyticsService } from '@crux/services';

/**
 * Partner customization
 */
import { PartnerCustomization } from './../../../partner-customization/partner-customization.helper';

/**
 * Store imports
 */
import { Store, select } from '@ngrx/store';
import { Product } from './../../../model/product';
import {
    SetProductsAction,
    GetQuestionsAction,
    GetSciQuestionsAction,
} from './../../../sales-store/products-store/actions';
import { AppetiteEffects } from './../../../sales-store/appetite-store/effects';
import { CommonCommunicationService } from '../../../services/common-communication.service';
import { Classification } from '../../../model/classification';
import { PartnersRedirectionFlags } from './../../../model/partners-redirection';
import {
    ResetEligibilityAction,
    ResetQuestionsAction,
    SetEligibilityAction,
    SetQuestionsAction,
    SetQuestionsAnswerAction,
    SetQuestionsErrorAction,
    SetSciCyberQuestionsAction,
    SetSciQuestionsAction,
} from './../../../sales-store/questions-store/actions';
import { environment } from '../../../../environments/environment';
import { ProductSCI } from '../../../csb-core/product-sci';
import { Questions } from '../../../model/questions';
import { ZipcodeModel } from '../../../model/zipcode';
import { AffinityPartnerFlags } from '../../../model/affinity-partner';
import { ProgressBarService } from '../../../services/progress-bar.service';
import leftRailSteps from '../../../../assets/json/left-rail-steps.json';

@Component({
    selector: 'app-products',
    templateUrl: './products.component.html',
    styleUrls: ['./products.component.scss'],
})
export class ProductsComponent implements OnInit {
    public hideQuoteAll = false;
    public selected = {};
    public labelDictionary: any;
    public pageURL = '';
    public noProducts = 'NoProducts';
    public classData: any;
    public products: Product[];
    public eligibilityQuestions: any;
    public R02_value: any = [];
    public showItems: any = [];
    storeQuestionsAnswer = [];

    /**
     * Please do not make sorted product's type as Product[]
     */

    public sortedProducts: any;
    showNotificationBuildingCov = false;
    public enableNext: any;
    public showProductsView = false;
    public showUmbrellaError = false;
    public showSpinnerInProductSelection = true;
    public revenue: number;
    public anyFeaturedProduct: boolean;
    public nonFeaturedProductsAvailable: boolean;
    public noProductsSelected = false;
    public enableNonFeaturedProducts = [];
    // Invalid partner connection params
    invalidPartnersField: any;
    PartnersRedirectionFlags: PartnersRedirectionFlags = new PartnersRedirectionFlags();
    showError: boolean;
    nonFeaturedProducts: any[] = [];
    AffinityPartnerFlags: AffinityPartnerFlags;
    isNoAppetite: boolean;

    constructor(
        private __ccs: CommonCommunicationService,
        private _router: Router,
        public dictionaryService: DictionaryService,
        private _analytics: AnalyticsService,
        public progressBarService: ProgressBarService,
        private _store: Store<{
            products: Product[];
            classification: Classification;
            revenue: number;
            partnersRedirectionFlag: PartnersRedirectionFlags;
            setEligibility: Questions[];
            zipcode: ZipcodeModel[];
            AffinityPartnerFlags: AffinityPartnerFlags;
        }>,
        private _appetiteEffects: AppetiteEffects,
        private _partnerCustomization: PartnerCustomization
    ) {
        this._store.pipe(select('classification')).subscribe((classData) => {
            this.classData = classData;
        });
        this._store.pipe(select('zipcode')).subscribe((zipData) => {
            if (zipData && zipData.length > 1) {
                zipData.forEach((address) => {
                    if (address.selected) {
                        const addressSelected = [];
                        addressSelected.push(address);
                        this.__ccs.stateSelected =
                            addressSelected[0].StateProvince;
                    }
                });
            } else {
                this.__ccs.stateSelected = zipData[0].StateProvince;
            }
        });
        this._store
            .pipe(select('AffinityPartnerFlags'))
            .subscribe((AffinityPartnerFlags) => {
                if (AffinityPartnerFlags && AffinityPartnerFlags.partnercode) {
                    if (
                        AffinityPartnerFlags.partnercode &&
                        !isNil(AffinityPartnerFlags.partnercode) &&
                        !isEmpty(AffinityPartnerFlags.partnercode)
                    ) {
                        this.AffinityPartnerFlags = AffinityPartnerFlags;
                    }
                }
            });
        this._store
            .pipe(select('setEligibility'))
            .subscribe((eligibilityFromStore) => {
                this.eligibilityQuestions = eligibilityFromStore;
                this.eligibilityQuestions.forEach((group) => {
                    if (group.group_name === 'Eligibility Questions') {
                        group.questions.forEach((question) => {
                            if (question.question_id === 'R02') {
                                this.R02_value = question.value;
                            }
                        });
                    }
                });
            });
        this._store.pipe(select('products')).subscribe((products) => {
            //--- SCI Product Filter Start----//
            const productSCI: ProductSCI = ProductSCI.instance();
            if (productSCI.onlySCIProductSelection) {
                products = ProductSCI.getSCIProduct(products);
            }
            this.products = products;

            this.products = this.products.filter(function (product) {
                return product.productId !== 'MICROMPL';
            });

            const R02_value = this.R02_value;

            const glExists = R02_value.indexOf('Liability') > -1 ? true : false;

            const bopExists = R02_value.indexOf('Property') > -1 ? true : false;

            if (this.products && this.products.length > 0) {
                this.products.forEach(function (item) {
                    item.displayProduct = false;
                    item.skipFlag = false;
                    if (
                        (item.name !== 'Business Owners Policy' &&
                            item.name !== 'General Liability' &&
                            item.name !== 'Umbrella' &&
                            R02_value.indexOf(item.name) < 0) ||
                        (item.name === 'Business Owners Policy' &&
                            !bopExists &&
                            !glExists) ||
                        (item.name === 'General Liability' && !glExists) ||
                        (item.name === 'Umbrella' && !glExists)
                    ) {
                        item.displayProduct = true;
                    }
                });

                if (this.products.every((el) => el.displayProduct === false)) {
                    this.noProductsSelected = true;
                }
                this.processInit();
            } else if (this.products) {
                /**
                 * Handle condition if there are no products available even if response came or
                 * BOP products has been removed for eligibilty rejection
                 */
                this.showProductsView = true;
                this.showSpinnerInProductSelection = false;
            }
        });
        this._store.pipe(select('revenue')).subscribe((amt) => {
            this.revenue = amt;
        });
        this._store
            .pipe(select('partnersRedirectionFlag'))
            .subscribe((error) => {
                this.invalidPartnersField = error;
            });
        this.showNotificationBuildingCovWithbop();
    }

    ngOnInit() {
        this.labelDictionary = this.dictionaryService.labelDict;
        if (
            this.AffinityPartnerFlags &&
            this.AffinityPartnerFlags.partnercode &&
            !isNil(this.AffinityPartnerFlags.partnercode) &&
            !isEmpty(this.AffinityPartnerFlags.partnercode) &&
            this.AffinityPartnerFlags.partnercode === 'CISA'
        ) {
            this.labelDictionary.productsText =
                'Select the insurance products needed for your client’s  business';
        }
        forEach(this.products, (product, index: number) => {
            product.selectedpackage = null;
            if (product.displayProduct === false) {
                product.selected = false;
            }
        });
        if (
            !isNil(this.AffinityPartnerFlags) &&
            !isEmpty(this.AffinityPartnerFlags)
        ) {
            if (
                this.AffinityPartnerFlags.partnercode &&
                !isNil(this.AffinityPartnerFlags.partnercode) &&
                !isEmpty(this.AffinityPartnerFlags.partnercode) &&
                this.AffinityPartnerFlags.enablePreSelectedProductFlow
            ) {
                leftRailSteps.forEach((step, index, obj) => {
                    if (step.title === 'Product Selection') {
                        obj.splice(index, 1);
                    }
                });
                leftRailSteps.forEach((step, index) => {
                    if (step.name === 'Step ') {
                        step.name = step.name + (index + 1).toString();
                    }
                });
                this.progressBarService.steps = leftRailSteps;
                if (this.__ccs.questionsLoaded) {
                    this.__ccs.questionsLoaded = false;
                    this._router.navigate(['/get-a-quote']);
                    return;
                }
                if (
                    this.AffinityPartnerFlags.eligibleProductsForPreSelection &&
                    this.AffinityPartnerFlags.eligibleProductsForPreSelection
                        .length > 0
                ) {
                    let eligibleProducts = [];
                    eligibleProducts = this.AffinityPartnerFlags
                        .eligibleProductsForPreSelection;
                    this.products = this.products.filter(function (product) {
                        return eligibleProducts.indexOf(product.productId) > -1;
                    });
                }
                if (this.products.length > 0) {
                    this.products.forEach((product) => {
                        product.selected = true;
                        product.displayProduct = true;
                        if (
                            this.AffinityPartnerFlags.partnercode === 'FCCS' &&
                            (product.productId === 'CYBER-ERM' ||
                                product.productId === 'CYBER-DIGITECT') &&
                            product.coverageAndLimits &&
                            product.coverageAndLimits.length > 0
                        ) {
                            product.coverageAndLimits.forEach((covg) => {
                                if (
                                    covg.limitAppliesToCd &&
                                    covg.limitAppliesToCd === 'PLCYLMT' &&
                                    covg.values &&
                                    covg.values.length > 0
                                ) {
                                    covg.values.forEach((subCovg) => {
                                        if (
                                            subCovg.subCoverage &&
                                            subCovg.subCoverage.length > 0 &&
                                            subCovg.subCoverage[0]
                                                .limitAppliesToCd &&
                                            subCovg.subCoverage[0]
                                                .limitAppliesToCd === 'DisLimit'
                                        ) {
                                            subCovg.subCoverage[0].defaultValue =
                                                '250000';
                                            subCovg.subCoverage[0].values = [
                                                '250000',
                                                '500000',
                                                '1000000',
                                            ];
                                        }
                                    });
                                }
                            });
                        }
                    });
                    this.continueToQuestions(false);
                } else if (this.products.length <= 0) {
                    this.isNoAppetite = true;
                    this.showProductsView = true;
                    this.showSpinnerInProductSelection = false;
                }
            }
        }
        this.checkForUmbrellaSelection();
    }

    processInit() {
        if (
            !isNil(this.AffinityPartnerFlags) &&
            !isEmpty(this.AffinityPartnerFlags) &&
            this.products &&
            this.products.length > 0
        ) {
            if (
                this.AffinityPartnerFlags.partnercode &&
                this.AffinityPartnerFlags.partnercode === 'ISBA10'
            ) {
                this.products = this.products.filter(function (product) {
                    return (
                        product.productId !== 'CYBER-ERM' &&
                        product.productId !== 'CYBER-DIGITECT'
                    );
                });
            }
        }
        this.reOrderProducts();
        forEach(this.products, (product) => {
            product.selectedpackage = null;
            this.nonFeaturedProducts.push(product);
            if (product.featured_ind && !this.anyFeaturedProduct) {
                this.anyFeaturedProduct = true;
            } else if (
                !product.featured_ind &&
                !this.nonFeaturedProductsAvailable
            ) {
                this.nonFeaturedProductsAvailable = true;
            } else if (
                !product.featured_ind &&
                !this.nonFeaturedProductsAvailable &&
                !this.anyFeaturedProduct
            ) {
                this.nonFeaturedProducts.push(product);
            }
        });

        this.enableNext =
            findIndex(this.products, { selected: true, displayProduct: true }) >
            -1;
        if (this.products.length > 0) {
            const displayProducts = filter(this.products, {
                displayProduct: true,
            });
            const uniqGroup = uniqBy(displayProducts, 'productGroup');
            if (uniqGroup.length === 1) {
                const uniqGroupName = uniqGroup[0].productGroup;
                let UmbrellaSelected = false;
                displayProducts.forEach((product) => {
                    if (product.name === 'Umbrella') {
                        UmbrellaSelected = true;
                    }
                });

                this.hideQuoteAll =
                    !UmbrellaSelected &&
                    filter(displayProducts, { productGroup: uniqGroupName })
                        .length <= 2;
            } else {
                this.hideQuoteAll =
                    uniqBy(displayProducts, 'productGroup').length < 2;
            }
        }
        if (
            isNil(this.AffinityPartnerFlags) ||
            isEmpty(this.AffinityPartnerFlags) ||
            (this.AffinityPartnerFlags &&
                this.AffinityPartnerFlags.partnercode &&
                !isNil(this.AffinityPartnerFlags.partnercode) &&
                !isEmpty(this.AffinityPartnerFlags.partnercode) &&
                !this.AffinityPartnerFlags.enablePreSelectedProductFlow)
        ) {
            this.showProductsView = true;
            this.showSpinnerInProductSelection = false;
        }
    }

    reOrderProducts() {
        this.sortedProducts = sortBy(this.products, [
            (product) => {
                return product.workflowOrder;
            },
        ]);
        this.enableNonFeaturedProducts = filter(this.products, {
            displayProduct: true,
            featured_ind: false,
        });
    }

    checkForUmbrellaSelection() {
        const selectedBopGroupProducts = filter(this.products, {
            selected: true,
            productGroup: 'BOP',
        });
        forEach(this.products, (product, index: number) => {
            if (product.name === 'Umbrella') {
                if (product.selected) {
                    if (selectedBopGroupProducts.length > 1) {
                        product.selected = true;
                        this.showUmbrellaError = false;
                    } else {
                        product.selected = false;
                        this.showUmbrellaError = true;
                    }
                }
            }
        });
    }

    selectProduct(productObj): void {
        if (this.invalidPartnersField) {
            const productErrorfield = find(
                this.invalidPartnersField.invalidParams,
                ['errorFieldId', 'products']
            );
            if (productErrorfield) {
                productErrorfield.showError = false;
            }
        }
        this.products.map((product) => {
            if (
                product.productGroup === productObj.productGroup &&
                product.productId !== productObj.productId &&
                productObj.productId !== 'UMBRELLA'
            ) {
                if (product.productId !== 'UMBRELLA') {
                    product.selected = false;
                }
            }
            if (!!product.policyTypeCode) {
                product.policyTypeCode = null;
            }
        });

        // Code should behave as usual for Cyber and MPL
        if (
            productObj.productGroup !== 'BOP' &&
            productObj.productId !== 'UMBRELLA'
        ) {
            // Toggle

            productObj.selected = !productObj.selected;
        } else if (productObj.productId === 'UMBRELLA') {
            // User is trying to deselect Umbrella
            if (productObj.selected) {
                productObj.selected = false;
            } else {
                // User is trying to select Umbrella

                // Check if BOP / GL selected
                if (this.checkBopGroupSelection()) {
                    productObj.selected = true;
                    this.showUmbrellaError = false;
                } else {
                    // display error
                    this.showUmbrellaError = true;
                }
            }
        } else if (productObj.productGroup === 'BOP') {
            // user trying deselect BOP group product

            if (productObj.selected) {
                productObj.selected = false;
                this.products.forEach((product) => {
                    if (product.productId === 'UMBRELLA' && product.selected) {
                        product.selected = false;
                        this.showUmbrellaError = true;
                    }
                });
            } else {
                // User is trying to select BOP group product
                productObj.selected = true;
                if (this.showUmbrellaError) {
                    this.showUmbrellaError = false;
                }
            }
        }

        /**
         * Dispact updated products object to setProductAction
         */
        this._store.dispatch(new SetProductsAction(this.products));

        /**
         * Enable next button
         */
        this.enableNext =
            findIndex(this.products, { selected: true, displayProduct: true }) >
            -1;

        /**
         * Track Selection and Deselection of Products.
         *
         * The `Action` is made into Select/Deselect based on the click.
         *
         * Know more about Anatomy of Event in Google Analytics with the below link:
         * @link https://support.google.com/analytics/answer/1033068#Anatomy
         */
        this._analytics.eventTrack.next({
            category: 'ProductSelection',
            action: productObj.selected ? 'SELECT' : 'DESELECT',
            label:
                productObj.name === 'Cyber ERM'
                    ? 'Cyber Insurance'
                    : productObj.name,
        });
        return;
    }

    checkBopGroupSelection() {
        // check if BOP / GL selected
        let bopSelectionFlag = false;
        this.products.forEach((product) => {
            if (product.selected && product.productGroup === 'BOP') {
                bopSelectionFlag = true;
            }
        });
        return bopSelectionFlag;
    }

    validUmbrellaSelection() {
        let umbrellaSelected = false;
        let validUmbrellaSelection = false;

        // check  if Umbrella is selected
        this.products.forEach((product) => {
            if (product.productId === 'UMBRELLA' && product.selected) {
                umbrellaSelected = true;
            }
        });

        // check if BOP / GL selected only if Umbrella is selected
        if (umbrellaSelected) {
            this.products.forEach((product) => {
                if (
                    product.selected &&
                    (product.productId === 'MICROBOP' ||
                        product.productId === 'MICROGL')
                ) {
                    validUmbrellaSelection = true;
                    this.convertMircoSelectionToSCI(product.productId);
                } else if (
                    product.selected &&
                    (product.productId === 'BOP' || product.productId === 'GL')
                ) {
                    validUmbrellaSelection = true;
                }
            });
        }

        if (umbrellaSelected && !validUmbrellaSelection) {
            return false;
        } else {
            return true;
        }
    }

    convertMircoSelectionToSCI(originalId) {
        const revisedProductId = originalId.split('MICRO')[1];
        this.products.forEach((product) => {
            if (product.productId === originalId) {
                product.selected = false;
            }
            if (product.productId === revisedProductId) {
                product.selected = true;
            }
        });
        // Dispatch products again as state has changed from Micro to SCI
        this._store.dispatch(new SetProductsAction(this.products));
    }

    continueToQuestions(selectAllProducts: boolean) {
        this.__ccs.InitialBusinessType = '';
        this.__ccs.InitialAddress = '';
        this.__ccs.InitialBusinessYear = '';

        if (!this.validUmbrellaSelection()) {
            return false;
        }

        this.products.forEach((product) => {
            if (product.selected) {
                product.createNewQuote = true;
                product.isEligible = true;
            }
        });

        // this.__ccs.stepNumber = 4;
        // this.__ccs.quoteRequestData.ratingUWQuestions = null;

        // The user is starting a new quote, they should be prompted with the idle message and email quote message
        // this.pccs.userIsComingFromRetrieveQuote = false;

        // Select all product before moving to the next step
        if (selectAllProducts) {
            this._analytics.eventTrack.next({
                category: 'ProductSelection',
                action: 'BUTTON_CLICK',
                label: 'Quote All Products',
            });
            let microBopProductCount = 0;
            let sciBopProductCount = 0;
            let isUmbrella = 0;

            this.products.forEach((productCount) => {
                if (
                    productCount.productSourceSystem === 'Micro' &&
                    productCount.productGroup === 'BOP'
                ) {
                    microBopProductCount += 1;
                }
                if (
                    productCount.productGroup === 'BOP' &&
                    productCount.productSourceSystem === 'SCI'
                ) {
                    sciBopProductCount += 1;
                }
                if (productCount.productId === 'UMBRELLA') {
                    isUmbrella += 1;
                }
            });
            const bopProducts = this.products.filter(
                (productObj) =>
                    (productObj.productId === 'BOP' ||
                        productObj.productId === 'MICROBOP') &&
                    productObj.displayProduct
            );

            this.products.forEach((product) => {
                if (
                    product.displayProduct &&
                    (product.productId === 'MICRO' + product.productGroup ||
                        product.productId === 'MICROGL')
                ) {
                    product.selected = true;
                }
                if (
                    isUmbrella > 0 &&
                    sciBopProductCount > 0 &&
                    product.productGroup === 'BOP' &&
                    product.productSourceSystem === 'Micro'
                ) {
                    product.selected = false;
                }
                if (
                    product.displayProduct &&
                    product.productId === 'WORKCOMP'
                ) {
                    product.selected = true;
                } else if (
                    product.displayProduct &&
                    product.productSourceSystem === 'SCI' &&
                    (isUmbrella > 0 || microBopProductCount === 0)
                ) {
                    product.selected = true;
                }

                if (
                    bopProducts &&
                    bopProducts.length > 0 &&
                    (product.productId === 'MICROGL' ||
                        product.productId === 'GL')
                ) {
                    product.selected = false;
                }
            });
        }

        const chosenProducts = filter(this.products, {
            selected: true,
            displayProduct: true,
        });
        this._analytics.eventTrack.next({
            category: 'ProductSelection',
            action: 'CHOSEN_PRODUCTS',
            label: map(chosenProducts, 'name').join(', '),
        });

        const questionsPayload = {};
        questionsPayload['classData'] = this.classData;
        questionsPayload['selectedProducts'] = this.products.filter(
            (productObj) => productObj.selected && productObj.displayProduct
        );
        const allSelectedProductsProductIds = questionsPayload[
            'selectedProducts'
        ].filter((selectedProduct) => {
            return selectedProduct;
        });

        questionsPayload[
            'allSelectedProductsProductIds'
        ] = convertArrayItemsToString(
            allSelectedProductsProductIds,
            'productId',
            this.AffinityPartnerFlags
        );
        const timer =
            this.__ccs.gotSciQuestions ||
            this.eligibilityQuestions.length > 0 ||
            this.__ccs.gotSciCyberQuestions
                ? 500
                : 0;

        if (this.eligibilityQuestions.length > 0) {
            const eligibilityQuestion = [];
            this._store.dispatch(
                new ResetEligibilityAction(eligibilityQuestion)
            );
        }

        if (this.__ccs.gotSciQuestions) {
            this.__ccs.gotSciQuestions = false;
            this._store.dispatch(new ResetQuestionsAction([]));
        }

        if (this.__ccs.gotSciCyberQuestions) {
            this.__ccs.gotSciCyberQuestions = false;
            this._store.dispatch(new SetSciCyberQuestionsAction([]));
        }
        this._store.dispatch(new SetQuestionsAction([]));
        this._store.dispatch(new SetQuestionsErrorAction(''));
        this._store.dispatch(
            new SetQuestionsAnswerAction(this.storeQuestionsAnswer)
        );
        this._store.dispatch(new GetQuestionsAction(questionsPayload));
        // }
        setTimeout(() => {
            this._partnerCustomization.goToNextScreen(this._router.url);
        }, timer);
    }

    filterExtraSCIProducts(product) {
        const productId = product.productId;
        let displayProductIndicator = true;
        if (productId.startsWith('MICRO')) {
            return displayProductIndicator;
        } else {
            this.products.forEach((productFromStore) => {
                if ('MICRO' + productId === productFromStore.productId) {
                    displayProductIndicator = false;
                }
            });
        }
        return displayProductIndicator;
    }

    readEmittedUmbrellaError(flag) {
        this.showUmbrellaError = flag;
    }
    showNotificationBuildingCovWithbop() {
        if (this.products !== undefined && this.products !== null) {
            this.products.forEach((product) => {
                if (
                    product.productGroup === 'BOP' &&
                    product.buildingCoverageWithbop === true
                ) {
                    this.showNotificationBuildingCov = true;
                }
            });
        }
    }
}

const convertArrayItemsToString = (array, prop, affinityPartnerFlags) => {
    let output = '';
    array.forEach((item) => {
        if (output) {
            output += ',' + item[prop];
        } else {
            output += item[prop];
        }
    });
    if (
        affinityPartnerFlags &&
        affinityPartnerFlags.partnercode &&
        !isNil(affinityPartnerFlags.partnercode) &&
        !isEmpty(affinityPartnerFlags.partnercode) &&
        affinityPartnerFlags.partnercode === 'CISA'
    ) {
        output = output.replace('WORKCOMP', 'SEMCIWC');
    }
    return output;
};
