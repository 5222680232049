import { Injectable, EventEmitter, Output } from '@angular/core';
import { QuoteRequestData } from './../model/quoterequestdata';
import { CallUsNumberModel } from './../model/callUsNumber';
import { Subject } from 'rxjs';
import { EmpLimits } from '../model/payrollLimits';
import { BusinessClass } from '../model/p360-business-class';

@Injectable()
export class CommonCommunicationService {
    static PARTNER_BOLDPENGUIN = 'boldpenguin';
    static PARTNER_BOLDPENGUIN_NONAMAZON = 'boldpenguindigitalnonamazon';
    static PARTNER_BRIZA = 'briza';
    // Holds whether the policy is for teamfocus or Amex. Defaults to Teamfocus(1)
    // Can be overwritten for amex (1)
    public PartnerId: any = 0;
    public emailJourneyTriggered = false;

    public quoteRequestData: QuoteRequestData;
    public newCallUsNumber: CallUsNumberModel = new CallUsNumberModel();
    public agencyTimings: string;

    // Experience state
    public stepNumber = 0;
    public formCompletionStep = 0;
    public showSpinner = false;

    // JSON based messages
    public applicationMessages: any;

    public isDisqualified = false;
    public makePaymentOptions: any;
    public isEditMode = false;
    public isBusinessClassEditMode = false;
    public stripeResponse: any;
    public retrievedQuoteObj: any;
    public simpleQuestionsObj: any;

    // Products Flag
    public enableNext = false;

    // Rate temporary
    public sciRateSaveResponse: any;

    // P360 total classes List
    public allP360ClassList: BusinessClass[];

    // Error screen content

    public errorScreenContent: any;

    // flags and data
    public isIdling = false;
    public productIndex = 0;
    public userIsComingFromRetrieveQuote = false;
    public userIsComingFromPartnersRedirection = false;

    // Email Quote flags and data
    public savedQuoteEmail = '';
    public userSavedQuoteViaEmail = false;
    public userSavedQuoteInIdleMessage = false;
    public emailJourneyStarted = false;

    public loadProducts = false;
    public numberOfSelectedPackages = 0;
    public quoteByEmail = false;
    public quoteDetailsList: any;
    showExpiredQuoteView = false;
    public retrieveQuoteFlowTriggered = false;
    public isSCIScenario = false;

    // Eligibility Questions
    public eligibilityQuestionsAnswers: any;

    // Partner redirection flags
    public isPartnerRedirected = false;

    public navPhoneNumber = '(866) 307-7121';
    public navPhoneNumberLink = 'tel:+18663077121';

    public isSearchActivate = false;
    public isNortonActive = false;
    public hasProduct = false;
    public showSpinnerInProductSelection: boolean;
    public typeOfReferral = '';
    public productItems = {
        referrals: [],
        products: [],
    };
    public totalPercentage = 0;
    public productsVisited = [];
    public preApproved = false;
    public preApproveCompanyName: string;

    public toberemovedUpdateRateRequest: any;
    constructor() {
        this.quoteRequestData = new QuoteRequestData();
    }
    public newrateQuote: any;
    public isSciFormInvalid: boolean;
    public previousInsurance = false;
    public scrollToMarketing: any;
    public scrollToWidget: any;
    public scrollToVideosSection: any;
    public scrollToQuestions: any;

    emailAddress: any;

    public routeBeforeSCIUW: string;
    // When user clicks on send email that email should be prefilled in contact info
    public emailSubject = new Subject<any>();
    public isShowIdleEmailSuccess = false;

    // Subject to react to change in the products when product is removed from cart
    public productChange = new Subject<boolean>();
    public isExpiredRetrieveQuote = false;
    public isBopAvailable = true;
    public classificationPrefill: string;

    public payrollLimits: any;

    public empLimits: EmpLimits;

    public caWaiverSectionChecked = false;

    public showCoverageAdvisorLink = false;
    public coverageAdvisorLink: string;
    public partnerSource: string;

    /* Initial values for business type and Adress */

    public InitialBusinessType = '';
    public InitialAddress = '';
    public InitialBusinessYear = '';
    public businessInsuredName = '';
    public productIndexFromURL: number;
    public newQuoteCreated = false;
    public sciGetquestionsTriggered = false;
    public gotSciQuestions = false;
    public attemptsToTriggerSciUWQ = 0;
    public gotSciCyberQuestions = false;
    public gotWCQuestions = false;
    public questionsLoaded = false;
    public claimedProducts = [];

    public recheckWcForms = new Subject<boolean>();
    public productDetailsBuilt: boolean;

    private _previousCompanyStructure = '';
    public set previousCompanyStructure(value: string) {
        this._previousCompanyStructure = value;
    }
    public get previousCompanyStructure(): string {
        return this._previousCompanyStructure;
    }

    private _currentCompanyStructure = '';
    public get currentCompanyStructure(): string {
        return this._currentCompanyStructure;
    }
    public set currentCompanyStructure(value: string) {
        this._currentCompanyStructure = value;
    }

    public productReceived = false;

    public navfontcolor: string;

    public RetrieveQuoteStatus: string;

    public stateSelected: string;

    public backendEnvVariable: string;

    public walmartPartner = false;
    public rsaPartner = false;
    public jovePartner = false;
    public callisPartner = false;
    public sentaraPartner = false;
    public recurringPay = false;
    public globalColor1 = '#FFB617';
    public globalColor2 = '#150F96';
    public globalColorHeader = '#150F96';
    public globalColorOutline = '#150F96';
    public homeBannerTextColor = '#000000';
    public rocketOutline = '#150F96';

    public showcisa = false;

    cisaReviewclickedbutn: any;

    gotConfirmation: boolean;

    public getHelpWorkFlowStage: string = 'Landing';

    public quoteCreated: boolean;

    public policyIssued: boolean;

    public retrievePageContent: any;

    public paymentDrawerOptions: any;

    public ssoUserIsAuthenticated: boolean;

    public cyberIQFlowTriggered: boolean;

    public firstTTSCodeTriggered: any;

    public firstTotalTTSApi: string;

    public serachTermFromNoneOfAbove: string;

    public ttsFromNoneOfAbove: any;

    public businessSelected: string;

    public isBoldPenguin(): boolean {
        return (
            this.PartnerId === CommonCommunicationService.PARTNER_BOLDPENGUIN ||
            this.PartnerId ===
                CommonCommunicationService.PARTNER_BOLDPENGUIN_NONAMAZON ||
            this.PartnerId === CommonCommunicationService.PARTNER_BRIZA
        );
    }

    public callUsNumber() {
        if (
            this.PartnerId === CommonCommunicationService.PARTNER_BOLDPENGUIN ||
            this.PartnerId === CommonCommunicationService.PARTNER_BRIZA
        ) {
            this.newCallUsNumber.phoneNumber = '(877) 262-9221';
            this.newCallUsNumber.phoneNumberLink = 'tel:+18772629221';
            return this.newCallUsNumber;
        } else if (this.PartnerId === 0 && this.walmartPartner) {
            this.newCallUsNumber.phoneNumber = '(833) 242-6278';
            this.newCallUsNumber.phoneNumberLink = 'tel:+18332426278';
            return this.newCallUsNumber;
        } else if (this.PartnerId === 0 && this.showcisa) {
            this.newCallUsNumber.phoneNumber = '(908) 572-3098';
            this.newCallUsNumber.phoneNumberLink = 'tel:+1908572-3098';
            return this.newCallUsNumber;
        } else if (this.PartnerId === 0 && this.rsaPartner) {
            this.newCallUsNumber.phoneNumber = '(833) 277-2662';
            this.newCallUsNumber.phoneNumberLink = 'tel:+18332772662';
            return this.newCallUsNumber;
        } else if (this.PartnerId === 0 && this.jovePartner) {
            this.newCallUsNumber.phoneNumber = '(888) 300-3970';
            this.newCallUsNumber.phoneNumberLink = 'tel:+18883003970';
            return this.newCallUsNumber;
        } else if (this.PartnerId === 0 && this.callisPartner) {
            this.newCallUsNumber.phoneNumber = '(360) 452-2314';
            this.newCallUsNumber.phoneNumberLink = 'tel:+13604522314';
            return this.newCallUsNumber;
        } else if (this.PartnerId === 0 && this.sentaraPartner) {
            this.newCallUsNumber.phoneNumber = '(617) 513-0759';
            this.newCallUsNumber.phoneNumberLink = 'tel:+16175130759';
            return this.newCallUsNumber;
        } else {
            this.newCallUsNumber.phoneNumber = '(866) 307-7121';
            this.newCallUsNumber.phoneNumberLink = 'tel:+18663077121';
            return this.newCallUsNumber;
        }
    }

    public getAgencyTimings() {
        if (this.PartnerId === 0 && this.callisPartner) {
            this.agencyTimings = '8:30AM-4:00PM Pacific M-F';
            return this.agencyTimings;
        } else if (this.PartnerId === 0 && this.sentaraPartner) {
            this.agencyTimings = '8:00AM-5:00PM EST M-F';
            return this.agencyTimings;
        } else {
            this.agencyTimings = '8:00AM-8:00PM EST M-F';
            return this.agencyTimings;
        }
    }
}
