import { Injectable } from '@angular/core';
import { CruxHttpClientService } from '@crux/services';
import { environment } from './../../environments/environment';
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { timeout, catchError } from 'rxjs/operators';
import { CommonCommunicationService } from './common-communication.service';
import { select, Store } from '@ngrx/store';
import { AffinityPartnerFlags } from '../model/affinity-partner';
import { isNil, isEmpty } from 'lodash-es';
import { AgentInformation } from '../model/agent-info';
@Injectable()
export class UpdateQuoteService {
    public updateQuoteURL = environment.backend + environment.uri.updateQuote;
    public updatewcQuoteUrl =
        environment.backend + environment.uri.wcUpdateQuote;
    affinityPartnerFlags = new AffinityPartnerFlags();
    partnerCode: string;
    constructor(
        private _http: CruxHttpClientService,
        private commonCommunicationService: CommonCommunicationService,
        private _store: Store<{
            AffinityPartnerFlags: AffinityPartnerFlags;
        }>
    ) {
        this._store
            .pipe(select('AffinityPartnerFlags'))
            .subscribe((AffinityPartnerFlags) => {
                if (AffinityPartnerFlags && AffinityPartnerFlags.partnercode) {
                    this.affinityPartnerFlags.partnercode =
                        AffinityPartnerFlags.partnercode;
                    if (
                        this.affinityPartnerFlags.partnercode &&
                        !isNil(this.affinityPartnerFlags.partnercode) &&
                        !isEmpty(this.affinityPartnerFlags.partnercode)
                    ) {
                        if (
                            this.affinityPartnerFlags.partnercode !== 'walmart'
                        ) {
                            if (
                                this.affinityPartnerFlags.partnercode ===
                                'cospring5'
                            ) {
                                this.partnerCode = 'capitalone5';
                            } else if (
                                this.affinityPartnerFlags.partnercode ===
                                'cospring10'
                            ) {
                                this.partnerCode = 'capitalone10';
                            } else {
                                this.partnerCode = this.affinityPartnerFlags.partnercode;
                            }
                        }
                    }
                }
            });
    }
    update(quoteRequestBody) {
        if (!isNil(this.partnerCode) && !isEmpty(this.partnerCode)) {
            this.updateQuoteURL = this.updateQuoteURL.replace(
                '#PARTNER#',
                this.partnerCode
            );
        } else {
            this.updateQuoteURL = this.updateQuoteURL.replace(
                '#PARTNER#',
                this.commonCommunicationService.PartnerId
            );
        }
        return this._http
            .put(this.updateQuoteURL, {
                body: quoteRequestBody,
                headers: new HttpHeaders({ apiversion: 'v2' }),
            })
            .pipe(
                timeout(120000), // 2 minutes
                catchError((error) => {
                    return observableThrowError(error);
                })
            );
    }

    updateWc(quoteRequestBody) {
        if (
            this.affinityPartnerFlags &&
            this.affinityPartnerFlags.partnercode &&
            !isNil(this.affinityPartnerFlags.partnercode) &&
            !isEmpty(this.affinityPartnerFlags.partnercode) &&
            this.affinityPartnerFlags.partnercode === 'CISA'
        ) {
            this.updatewcQuoteUrl =
                environment.backend + environment.uri.semciWcUpdateQuote;
        }
        if (
            this.commonCommunicationService.backendEnvVariable &&
            !isNil(this.commonCommunicationService.backendEnvVariable) &&
            !isEmpty(this.commonCommunicationService.backendEnvVariable)
        ) {
            const headers = {};
            headers['env'] = this.commonCommunicationService.backendEnvVariable;
            return this._http
                .put(this.updatewcQuoteUrl, {
                    body: quoteRequestBody,
                    headers: new HttpHeaders(headers),
                })
                .pipe(
                    timeout(120000), // 2 minutes
                    catchError((error) => {
                        return observableThrowError(error);
                    })
                );
        } else {
            return this._http
                .put(this.updatewcQuoteUrl, {
                    body: quoteRequestBody,
                })
                .pipe(
                    timeout(120000), // 2 minutes
                    catchError((error) => {
                        return observableThrowError(error);
                    })
                );
        }
    }
}
