import { Component, OnInit } from '@angular/core';
import { ClassificationService } from '../../../../services/classification.service';
import { AnalyticsService } from '@crux/services';
import { Classification } from '../../../../model/classification';
import {
    FormControl,
    FormBuilder,
    FormGroup,
    Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import {
    SetClassificationAction,
    SetP360ClassAction,
} from '../../../../sales-store/appetite-store/actions';
import { CommonCommunicationService } from '../../../../services/common-communication.service';
import { StepItemBase } from '../../../../csb-core/step-navigator/step-item-base.component';
import { DictionaryService } from '../../../../services/dictionary.service';
import { QuestionBase } from '../../../../csb-quote/csb-question/csb-question-base.component';
import { ApplicationMessagesService } from '../../../../services/application-messages.service';
import { GAEvent } from '../../../../csb-core/ga-event';
import { AffinityPartnerFlags } from '../../../../model/affinity-partner';
import { isEmpty, isNil } from 'lodash';
import {
    SetProductsAction,
    SetProductsStatusAction,
} from '../../../../sales-store/products-store/actions';
import { BusinessClass } from '../../../../model/p360-business-class';

@Component({
    selector: 'app-csb-business-type',
    templateUrl: './csb-business-type.component.html',
    styleUrls: ['./csb-business-type.component.scss'],
})
export class CsbBusinessTypeComponent extends QuestionBase implements OnInit {
    classificationForm: FormGroup;
    classificationInputCtrl: FormControl;
    classificationURL: string;
    classification: Classification;
    classificationPrefill: string;
    numberOfRecords: string;
    isclassificationValid = false;
    noMatch = false;
    question: string;
    errorMessages: any;
    classificationInvalid = false;
    cssClass: Array<string>;
    componentClass: Array<string>;
    AffinityPartnerFlags: AffinityPartnerFlags;
    changeErrorMessage: boolean;
    autocomptypefield = false;
    keyWord: string = '';

    constructor(
        public commonCommunicationService: CommonCommunicationService,
        private dictionaryService: DictionaryService,
        private _fb: FormBuilder,
        private _classificationData: ClassificationService,
        private _analytics: AnalyticsService,
        private applicationMessagesService: ApplicationMessagesService,
        private _router: Router,
        private _store: Store<{
            classification: Classification;
            AffinityPartnerFlags: AffinityPartnerFlags;
            p360TotalClassesInfo: BusinessClass[];
        }>
    ) {
        super();
        this.question = this.dictionaryService.labelDict.banner.quoteStep.typeOfBusiness;
        this.numberOfRecords = '3000';
        this.classificationURL = this._classificationData.classificationServiceUrl.replace(
            'numberOfRecords',
            this.numberOfRecords
        );
        this._store
            .pipe(select('AffinityPartnerFlags'))
            .subscribe((AffinityPartnerFlags) => {
                if (AffinityPartnerFlags && AffinityPartnerFlags.partnercode) {
                    if (
                        AffinityPartnerFlags.partnercode &&
                        !isNil(AffinityPartnerFlags.partnercode) &&
                        !isEmpty(AffinityPartnerFlags.partnercode)
                    ) {
                        this.AffinityPartnerFlags = AffinityPartnerFlags;
                        this.classificationURL = this.classificationURL.replace(
                            'name',
                            this.AffinityPartnerFlags.utm_source
                        );
                        if (
                            this.AffinityPartnerFlags.partnercode === 'walmart'
                        ) {
                            this.changeErrorMessage = true;
                        }

                        if (
                            this.AffinityPartnerFlags.utm_source ===
                                'walmart' ||
                            this.AffinityPartnerFlags.utm_source === 'ISBA' ||
                            this.AffinityPartnerFlags.utm_source === 'RSA' ||
                            this.AffinityPartnerFlags.utm_source === 'amazon' ||
                            this.AffinityPartnerFlags.utm_source === 'briza'
                        ) {
                            this.autocomptypefield = true;
                        }
                    }
                } else {
                    this.classificationURL = this.classificationURL.replace(
                        'name',
                        ''
                    );
                }
            });
        this._store
            .pipe(select('p360TotalClassesInfo'))
            .subscribe((classList: BusinessClass[]) => {
                this.autocomptypefield = false;
                if (
                    !isNil(classList) &&
                    !isEmpty(classList) &&
                    classList.length > 0
                ) {
                    this.autocomptypefield = true;
                    if (
                        this.AffinityPartnerFlags &&
                        this.AffinityPartnerFlags.partnercode &&
                        this.AffinityPartnerFlags.partnercode === 'RSA10'
                    ) {
                        let rsaIndex = classList.findIndex(
                            (item) =>
                                item.tts === 'Mail Box or Packaging Stores'
                        );
                        if (rsaIndex > -1) {
                            classList[rsaIndex].tts = 'Retail Shipping Store';
                        }
                    }
                    this.commonCommunicationService.allP360ClassList = classList;
                }
            });
    }

    ngOnInit() {
        this.cssClass = this.showImage
            ? ['col-4', 'col-md-4', 'col-sm-6']
            : ['col-7', 'col-md-7', 'col-sm-6'];

        this.componentClass = this.showImage
            ? ['col-5', 'col-md-5', 'col-sm-12']
            : ['col-5', 'col-md-5', 'col-sm-6'];

        this.errorMessages = this.applicationMessagesService.messageDictionary.homePageQuestions;
        if (!this.autocomptypefield) {
            this.classificationInputCtrl = new FormControl(
                '',
                Validators.required
            );
            // this.isclassificationValid = true;
            // this.enableNext();
        } else {
            this.classificationInputCtrl = new FormControl(
                '',
                Validators.required
            );
        }

        this.classificationForm = this._fb.group({
            classificationInputCtrl: this.classificationInputCtrl,
        });
        this.readDataFromStore();
    }

    readDataFromStore() {
        // Subscribe to Classification
        this._store
            .pipe(select('classification'))
            .subscribe((classification) => {
                if (!this.classification) {
                    this.classification = classification;
                    if (
                        !this.autocomptypefield &&
                        this.classification &&
                        this.classification.keyWord &&
                        !isNil(this.classification.keyWord) &&
                        !isEmpty(this.classification.keyWord)
                    ) {
                        this.keyWord = classification.keyWord;
                        this.classificationInputCtrl.setValue(
                            classification.keyWord
                        );
                    } else {
                        if (
                            !isNil(this.classification) &&
                            !isEmpty(this.classification)
                        ) {
                            this.classificationInputCtrl.setValue(
                                classification
                            );
                        }
                    }
                }
            });
    }

    classificationSelection() {
        if (
            this.classificationInputCtrl.value.tts &&
            (this.classificationInputCtrl.value.tts_code ||
                this.classificationInputCtrl.value.bop_id) &&
            !this.classificationInputCtrl.value.undefined
        ) {
            this.classification = { ...this.classificationInputCtrl.value };
            this.classificationInvalid = false;
            this.noMatch = false;
            this.isclassificationValid = true;
            /**
             * Track Selection of Classification.
             */

            if (this.gaevent) {
                GAEvent.CapturEvent(
                    this._analytics,
                    this.gaevent.action,
                    this.gaevent.category,
                    this.classificationInputCtrl.value.name
                );
            }
            //GA Event to track user input for type of business
            this._analytics.eventTrack.next({
                category: 'Get_A_Quote',
                action: 'CLASSIFICATION_CHOICE',
                label:
                    'Business Description: ' +
                    this.classificationInputCtrl.value.name,
            });
            let classSelected = {
                digitalClassCode: this.classificationInputCtrl.value.tts_code,
                classId: this.classificationInputCtrl.value.bop_id,
                name: this.classificationInputCtrl.value.tts,
                description: this.classificationInputCtrl.value
                    .long_description,
                tags: this.classificationInputCtrl.value.sic_description,
                premiumBasis: this.classificationInputCtrl.value.gl_expo_bs_c,
                wsgClassId: '',
                keyWord: '',
            };
            // Dispatch the store with selected classification details recieved from typeahead
            this._store.dispatch(new SetClassificationAction(classSelected));
        } else if (
            !this.autocomptypefield &&
            this.classificationInputCtrl.value
        ) {
            if (
                this.classificationInputCtrl.value &&
                !isNil(this.classificationInputCtrl.value) &&
                !isEmpty(this.classificationInputCtrl.value)
            ) {
                this.classification = { ...this.classificationInputCtrl.value };
                this.classificationInvalid = false;
                this.noMatch = false;
                this.isclassificationValid = true;
                let classSelected = {
                    digitalClassCode: '',
                    classId: '',
                    name: '',
                    description: '',
                    tags: '',
                    premiumBasis: '',
                    wsgClassId: '',
                    keyWord:
                        this.classificationInputCtrl.value &&
                        this.classificationInputCtrl.value.keyWord
                            ? this.classificationInputCtrl.value.keyWord
                            : this.classificationInputCtrl.value,
                };
                this.commonCommunicationService.firstTTSCodeTriggered = '';
                this.commonCommunicationService.firstTotalTTSApi = '';
                this.commonCommunicationService.serachTermFromNoneOfAbove = '';
                this.commonCommunicationService.ttsFromNoneOfAbove = '';
                this.commonCommunicationService.businessSelected = '';
                this._store.dispatch(new SetP360ClassAction([]));
                this._store.dispatch(
                    new SetClassificationAction(classSelected)
                );
            } else {
                this.classificationInvalid = true;
                this.noMatch = false;
                this.isclassificationValid = false;
            }
        } else {
            if (
                (this.classificationInputCtrl.value.undefined &&
                    (isNil(this.classificationInputCtrl.value.tts) ||
                        isEmpty(this.classificationInputCtrl.value.tts))) ||
                isNil(this.classificationInputCtrl.value) ||
                isEmpty(this.classificationInputCtrl.value)
            ) {
                this.classificationInvalid = true;
                this.noMatch = false;
                this.isclassificationValid = false;
            } else if (
                this.classificationInputCtrl.value.undefined &&
                !isNil(this.classificationInputCtrl.value.tts) &&
                !isEmpty(this.classificationInputCtrl.value.tts) &&
                this.classificationInputCtrl.value.nomatch
            ) {
                this.noMatch = true;
                this.classificationInvalid = false;
                this.isclassificationValid = false;
            }
        }
        this.enableNext();
    }

    handleFocusOut() {
        if (this.gaevent) {
            GAEvent.CapturEvent(
                this._analytics,
                this.gaevent.action,
                this.gaevent.category,
                this.classificationInputCtrl.value.name
                    ? this.classificationInputCtrl.value.name
                    : this.classificationInputCtrl.value
            );
        }
    }

    enableNext() {
        if (!this.isclassificationValid) {
            this.valid(false, this.classificationInputCtrl.value);
        } else {
            this.valid(true, this.classificationInputCtrl.value);
        }
    }
}
